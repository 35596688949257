import { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import ChatPanel from './ChatPanel';
import { getSocket } from 'useSocket';
import { styled } from '@mui/system';
import BettersPanel from './BettersPanel';
import PlaceBetsPanel from './PlaceBetsPanel';
import { green, grey, red } from '@mui/material/colors';
import { Button, Grid, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import plslogo from '../../../assets/hexbwlogo.svg';

// Define keyframes for the animation to move up and to the left and fade out
const fadeMove = keyframes`
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-20px, -20px); /* Move slightly up and left */
  }
`;

// Styled component for animated text
const AnimatedCashOut = styled(Typography)(({ theme }) => ({
    position: 'fixed',
    animation: `${fadeMove} 2s ease-in-out forwards`,
    color: grey[200],
    fontSize: 18,
    fontWeight: 900
}));

const CrashGameMain = () => {
    const socket = getSocket();
    const notificationCounter = useRef(0);
    const [gameState, setGameState] = useState('');
    const [firstBarTS, setFirstBarTS] = useState();
    const [multiplier, setMultiplier] = useState(1.0);
    const [crashMessage, setCrashMessage] = useState('');
    const [series, setSeries] = useState([{ data: [] }]);
    const [lastCrashValues, setLastCrashValues] = useState([]);
    const [bettingCountdown, setBettingCountdown] = useState(30);
    const [cashoutNotifications, setCashoutNotifications] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            type: 'candlestick',
            height: 350,
            foreColor: grey[500],
            animations: {
                enabled: false // Disable animations for candlestick chart
            }
        },
        title: {
            text: 'Powered by Chance.Game',
            align: 'left',
            style: {
                color: grey[400]
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: false
            },
            min: undefined,
            max: undefined,
            tickAmount: 10
        },
        yaxis: {
            tooltip: {
                enabled: false
            },
            min: 1,
            max: 1.8,
            tickAmount: 8
        },
        grid: {
            show: true, // Show grid
            borderColor: grey[800], // Set the color of grid borders
            xaxis: {
                lines: {
                    show: true // Disable vertical grid lines
                }
            },
            yaxis: {
                lines: {
                    show: true // Disable horizontal grid lines
                }
            }
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#00B746',
                    downward: '#EF403C'
                }
            }
        }
    });

    useEffect(() => {
        const handleCountdown = ({ countdown }) => {
            setBettingCountdown(countdown);
        };

        socket.on('bettingCountdown', handleCountdown);
        socket.on('connect', () => {
            console.log('Connected to server');
        });
        socket.on('lastCrashValues', (values) => {
            setLastCrashValues(values);
        });
        socket.on('playerCashedOut', (data) => {
            // Add the new cash-out notification to the array
            setCashoutNotifications((prevNotifications) => [...prevNotifications, data]);

            // Remove the notification after 2 seconds
            setTimeout(() => {
                setCashoutNotifications((prevNotifications) =>
                    prevNotifications.filter((notification) => notification.playerAddress !== data.playerAddress)
                );
            }, 2000); // Matches the animation duration
        });
        socket.on('bettingPhaseStarted', () => {
            setCrashMessage('');
            setFirstBarTS(null);
            setMultiplier(1.0);
            setSeries([{ data: [] }]);
            console.log('Betting phase started');
            setOptions((prevOptions) => ({
                ...prevOptions,
                yaxis: {
                    ...prevOptions.yaxis,
                    min: 0,
                    max: 1.8
                }
            }));
        });
        socket.on('gameState', (data) => {
            setGameState(data.state);
        });

        socket.on('multiplierUpdate', (data) => {
            const newMultiplier = parseFloat(data.multiplier);
            setMultiplier(newMultiplier);

            // Ensure each candlestick appears to start from the bottom
            setSeries((prevSeries) => {
                const currentDate = new Date();
                const previousDataPoint = prevSeries[0].data.length ? prevSeries[0].data[prevSeries[0].data.length - 1] : null;
                const open = previousDataPoint ? previousDataPoint.y[3] : newMultiplier; // Previous close or new multiplier for the first one
                const close = newMultiplier;
                const high = close + Math.random() * 0.05; // Close is close to High, small difference
                const low = open - Math.random() * 0.1; // Open is close to Low, small difference

                const newDataPoint = {
                    x: currentDate,
                    y: [open, high, low, close] // [open, high, low, close]
                };
                const updatedSeries = [...prevSeries[0].data, newDataPoint];
                if (!firstBarTS) {
                    setFirstBarTS(updatedSeries[0].x);
                }

                setOptions((prevOptions) => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        min: firstBarTS ? firstBarTS - 30000 : new Date().getTime() - 30000, // 1 minute before the first candlestick or current time
                        max: new Date().getTime() + 1000
                    },
                    yaxis: {
                        ...prevOptions.yaxis,
                        min: 1,
                        max: newMultiplier * 1.4 // Update max dynamically
                    }
                }));

                return [
                    {
                        data: updatedSeries
                    }
                ];
            });

            console.log('Multiplier Update:', data.multiplier);
        });
        socket.on('crash', (data) => {
            setCrashMessage(`Crashed at ${data.crashMultiplier}x!`);
            const crashMultiplier = parseFloat(data.crashMultiplier);

            // Add a red candlestick for the crash
            setSeries((prevSeries) => {
                const currentDate = new Date();
                const updatedSeries = [...prevSeries[0].data];
                const lastDataPointIndex = updatedSeries.length - 1;
                if (lastDataPointIndex >= 0) {
                    const lastDataPoint = updatedSeries[lastDataPointIndex];
                    updatedSeries[lastDataPointIndex] = {
                        ...lastDataPoint,
                        fillColor: red[500], // Make the last candlestick red
                        strokeColor: red[500] // Optionally set the border color to red as well
                    };
                }
                const newDataPoint = {
                    x: currentDate,
                    y: [crashMultiplier, crashMultiplier, 0, 0], // Small variation for crash
                    fillColor: red[500], // Make the new crash candlestick red
                    strokeColor: red[500] // Optionally set the border color to red as well
                };
                return [
                    {
                        data: [...prevSeries[0].data, newDataPoint]
                    }
                ];
            });
            setOptions((prevOptions) => ({
                ...prevOptions,
                yaxis: {
                    ...prevOptions.yaxis,
                    min: -0.5
                }
            }));

            console.log('Game crashed at:', data.crashMultiplier);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        return () => {
            socket.disconnect();
            socket.off('bettingCountdown', handleCountdown);
        };
    }, [socket]);

    let StateText;
    if (gameState === 'betting') {
        StateText = `Game starts in ${bettingCountdown}. Place your bets.`;
    } else if (gameState === 'inProgress') {
        StateText = `${multiplier.toFixed(2)}x`;
    } else if (gameState === 'assigningData') {
        StateText = 'Rewarding users on-chain!';
    } else if (gameState === 'idle') {
        StateText = 'New round starts shortly...';
    } else if (gameState === 'failed') {
        StateText = 'Fetching data...';
    }

    const formatAccount = (account) => {
        if (account && account.length > 8) {
            const firstPart = account.slice(0, 4);
            const lastPart = account.slice(-4);
            return `${firstPart}...${lastPart}`;
        }
        return 'undefined'; // return the original if it's too short
    };

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={8}>
                <Grid
                    container
                    display="flex"
                    sx={{ justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' } }}
                    width="97%"
                    mt={3}
                >
                    <Grid
                        container
                        sx={{ width: { lg: '66%', md: '66%', sm: '95%', xs: '95%' } }}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            lg={7.5}
                            md={7.5}
                            xs={12}
                            sx={{
                                height: 400,
                                borderRadius: 3,
                                border: 2,
                                borderColor: grey[700]
                            }}
                        >
                            <Grid
                                container
                                px={2}
                                mb={1}
                                py={0.5}
                                height={40}
                                display="flex"
                                borderBottom={2}
                                alignItems="center"
                                borderColor={grey[700]}
                                justifyContent="space-between"
                            >
                                {lastCrashValues.map((value, index) => (
                                    <Typography key={index} sx={{ color: value > 2 ? green[500] : grey[300], fontWeight: 900 }}>
                                        {Math.round(value * 100)}%
                                    </Typography>
                                ))}
                            </Grid>
                            <ReactApexChart options={options} series={series} type="candlestick" height={300} width="100%" />
                            <Grid container display="flex" justifyContent="center" alignItems="center" mt={-25}>
                                <img
                                    src={plslogo}
                                    alt="logo"
                                    style={{ position: 'absolute', backgroundSize: 'cover', height: 230, marginTop: 90, opacity: 0.2 }}
                                />
                                {/* Render cash-out notifications */}
                                {cashoutNotifications.map((notification, index) => (
                                    <AnimatedCashOut key={index}>
                                        {`${formatAccount(notification.playerAddress)} ${Math.round(
                                            notification.cashoutMultiplier * 100
                                        )}%`}
                                    </AnimatedCashOut>
                                ))}
                            </Grid>
                            <Grid item display="flex" flexDirection="column" justifyContent="left" zIndex={999} ml={3} mt={23}>
                                <Typography textAlign="left" sx={{ color: grey[300], fontWeight: 900, fontSize: 14 }}>
                                    Max bet: 500K PLS
                                </Typography>
                                <Typography textAlign="left" sx={{ mt: 0.3, color: grey[300], fontWeight: 900, fontSize: 14 }}>
                                    Max win: 25M PLS
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                sx={{
                                    background: 'transparent',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: -30
                                }}
                            >
                                <Grid container width="60%" display="flex" justifyContent="center" zIndex={999}>
                                    <Typography textAlign="center" sx={{ color: grey[100], fontWeight: 900, fontSize: 26 }}>
                                        {crashMessage || null}
                                    </Typography>
                                </Grid>
                                <Grid container width="60%" display="flex" justifyContent="center" zIndex={999}>
                                    <Typography textAlign="center" sx={{ color: grey[100], fontWeight: 900, fontSize: 26 }}>
                                        {StateText}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <PlaceBetsPanel />
                        <ChatPanel />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            borderRadius: 3,
                            height: { lg: 'auto', md: 'auto', sm: 400, xs: 400 },
                            mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                            mb: 5,
                            border: 2,
                            borderColor: grey[700],
                            width: { lg: '33%', md: '33%', sm: '95%', xs: '95%' }
                        }}
                    >
                        <BettersPanel />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CrashGameMain;
